import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userReducer from "./reducers/userReducer";
import featureFlagReducer from "./reducers/featureFlagReducer";

const createStore = () =>
  configureStore({
    reducer: combineReducers({ user: userReducer, flags: featureFlagReducer }),
  });

export default createStore;
