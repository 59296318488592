// Set clientSideID to your LaunchDarkly client-side ID
import { initialize } from "launchdarkly-js-client-sdk";

const installLD = () => {
  const user = {
    key: "not-logged",
  };
  // eslint-disable-next-line no-undef
  const clientSideID = process.env.VITE_LD_SHARED_CLIENT_ID_KEY ?? "";
  return initialize(clientSideID, user);
};

export default installLD;
