const reducersEnum = {
  USER: {
    SET: "user/set",
    LOGOUT: "user/logout",
  },
  FLAG: {
    SET: "featureFlags/set",
    SET_ALL: "featureFlags/setAll",
  },
};

export default reducersEnum;
