/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import initialState from "../initialState";
import reducersEnum from "./reducersEnum";

function userReducer(state = initialState, action) {
  if (action.type === reducersEnum.USER.SET) {
    return {
      ...action.payload,
    };
  }

  if (action.type === reducersEnum.USER.LOGOUT) {
    return null;
  }

  return state;
}

export default userReducer;
