import initialState from "../initialState";

function featureFlagReducer(state = initialState.flags, action) {
  if (action.type === "featureFlags/setAll") {
    return {
      ...action.payload,
    };
  }

  if (action.type === "featureFlags/set") {
    const { featureFlag, flagValue } = action.payload;

    return {
      ...state,
      [featureFlag]: flagValue,
    };
  }

  return state;
}

export default featureFlagReducer;
