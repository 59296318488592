import jwtDecode from "jwt-decode";
import getUserToken from "./getUserToken";

const isLoggedIn = () => {
  const jwtToken = getUserToken();
  if (!jwtToken) return false;
  const decodedJWT = jwtDecode(jwtToken);
  return Date.now() <= decodedJWT.exp * 1000;
};

export default isLoggedIn;
