import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import createStore from "./store/createStore";
import isLoggedIn from "./utils/user/isLoggedIn";
import reducersEnum from "./store/reducers/reducersEnum";
import getUserFromJWT from "./utils/user/getUserFromJWT";
import installLD from "./installLD";

const store = createStore();
const data = {
  props: { globalStore: store },
};

const ldClient = installLD();

ldClient.on("ready", () => {
  store.dispatch({
    type: reducersEnum.FLAG.SET_ALL,
    payload: ldClient.allFlags(),
  });
  ldClient.on("change", (payload) => {
    Object.keys(payload).forEach((flag) => {
      store.dispatch({
        type: reducersEnum.FLAG.SET,
        payload: {
          featureFlag: flag,
          flagValue: payload[flag].current,
        },
      });
    });
  });
});

if (isLoggedIn()) {
  const currentAgent = getUserFromJWT();
  store.dispatch({ type: reducersEnum.USER.SET, payload: currentAgent });

  const ldUser = {
    key: currentAgent.email,
    email: currentAgent.email,
    custom: {
      roles: currentAgent.roles,
    },
  };

  ldClient.identify(ldUser);
} else {
  store.dispatch({ type: reducersEnum.USER.LOGOUT });
}

const routes = constructRoutes(document.querySelector("#justos-layout"), data);

const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});

const isOutOfLogin = () => {
  return !window.location.pathname.includes("/login");
};

const headerApp = applications.find((app) => app.name === "@justos/header");
headerApp.activeWhen = [isOutOfLogin];

const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

start({
  urlRerouteOnly: true,
});
